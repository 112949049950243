<template>
  <component :is="component" />
</template>

<script setup lang="ts">
/* eslint-disable import/extensions */
import { compile, defineComponent, type PropType } from 'vue';
import { type ISbRichtext } from '@storyblok/js';
import { renderRichText } from '@/utils/richtext';
import * as nodes from '@/components/rich-text/nodes';
import RichTextBlok from './RichTextBlok.vue';
import NuxtLink from '#app/components/nuxt-link';

const props = defineProps({
  text: { type: Object as PropType<ISbRichtext>, required: true },
  type: { type: String as PropType<'default' | 'article'>, default: 'default' },
});

const component = defineComponent({
  components: {
    NuxtLink,
    RichTextBlok,
    ...nodes,
  },
  setup: () => compile(renderRichText(props.text, props.type)),
});
</script>
