<template>
  <component :is="widget" v-if="widget" :blok="blok" />
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { safeDecodeAttribute } from '@/utils/attributes';
import * as widgets from './widgets';

const props = defineProps({
  component: { type: String, required: true },
  blokData: { type: String, required: true },
});

// @ts-ignore
const widget = computed(() => widgets[props.component]);
const blok = computed(() => JSON.parse(safeDecodeAttribute(props.blokData)));
</script>
