import { RichTextSchema } from '@storyblok/js';
import { cloneDeep } from 'lodash-es';
import { isAbsoluteUrl } from '@/utils/url';
import { safeEncodeAttribute } from '@/utils/attributes';
import { replaceVariablesInLinkWrapper } from '@/utils/richtext/customVariables';
import Bugsnag from '@bugsnag/js';

const DefaultSchema = cloneDeep({ ...RichTextSchema });

// optimise anchor links by using NuxtLink
DefaultSchema.marks.link = ({ attrs }) => {
  try {
    if (attrs.href != null) {
      attrs.href = replaceVariablesInLinkWrapper(decodeURIComponent(attrs.href));
    }
  } catch (error) {
    Bugsnag.notify(error);
  }
  const external = (attrs.href && isAbsoluteUrl(attrs.href)) || attrs.href.startsWith('/api');
  return {
    tag: [
      {
        tag: external ? 'a' : 'NuxtLink',
        attrs,
      },
    ],
  };
};

const ArticleSchema = cloneDeep({ ...DefaultSchema });

// eslint-disable-next-line @typescript-eslint/no-unused-vars
ArticleSchema.nodes.image = ({ attrs: { meta_data: _, alt, src, title } }) => {
  return {
    singleTag: [
      {
        tag: 'ImageNode',
        attrs: { alt: safeEncodeAttribute(alt), src, title: safeEncodeAttribute(title) },
      },
    ],
  };
};

ArticleSchema.nodes.heading = ({ attrs }) => {
  return {
    tag: [
      {
        tag: 'Heading',
        attrs,
      },
    ],
  };
};

ArticleSchema.marks.code = () => {
  return {
    tag: [
      {
        tag: 'code',
        attrs: { 'v-pre': true },
      },
    ],
  };
};

export { DefaultSchema, ArticleSchema };
