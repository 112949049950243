import { type ISbRichtext, renderRichText as _renderRichText, type SbRichTextOptions } from '@storyblok/js';
import { RichtextResolver } from 'storyblok-js-client';
import { safeEncodeAttribute } from '@/utils/attributes';
import { ArticleSchema, DefaultSchema } from './schema';

export type RichTextType = 'default' | 'article';

function getRichTextOptions(type: RichTextType): SbRichTextOptions {
  switch (type) {
    case 'article':
      return {
        schema: ArticleSchema,
        resolver: (component, blok) => {
          return `<RichTextBlok blokData="${safeEncodeAttribute(JSON.stringify(blok))}" component="${component}" />`;
        },
      };
    default:
      return { schema: DefaultSchema, resolver: () => '' };
  }
}

export function renderRichText(data: ISbRichtext, type: RichTextType) {
  const options = getRichTextOptions(type);
  return _renderRichText(data, options, new RichtextResolver(options.schema));
}
