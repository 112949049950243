<template>
  <div
    v-if="card"
    :class="[
      'signup-card my-4 flex flex-col p-4 rounded-lg',
      isVonageBrand
        ? 'justify-around sm:flex-row gap-4 sm:px-8 sm:py-2 sm:gap-8'
        : 'justify-between md:flex-row gap-8 md:p-0 md:gap-0',
    ]"
  >
    <div class="flex flex-col justify-center items-center">
      <StoryblokImage
        :class="!isVonageBrand ? 'w-full xl:h-[150px] sm:h-auto md:h-full max-h-[240px]' : 'w-[128px]'"
        :image="card.image"
      />
    </div>
    <div
      :class="['flex flex-col justify-center basis-1/2 text-canvas', { 'my-0 md:my-4 px-0 md:px-4': !isVonageBrand }]"
    >
      <RichText :text="card.content" />
    </div>
    <div
      :class="[
        'flex flex-col justify-center items-center vvd-theme-alternate',
        { 'my-4 p-4 gap-4 md:px-8 md:py-2 md:gap-8': !isVonageBrand },
      ]"
    >
      <NuxtLink :href="VonageBlogArticleSignUpURL" type="button">
        <VButton
          id="signup"
          :label="card.buttonLabel"
          :appearance="isVonageBrand ? 'filled' : 'outlined'"
          :connotation="isVonageBrand ? '' : 'cta'"
          :icon="card.buttonIcon"
          icon-trailing
        />
      </NuxtLink>
    </div>
  </div>
</template>

<script setup lang="ts">
import { VButton } from '@vonage/vivid-vue';
import StoryblokImage from '@/components/storyblok/StoryblokImage.vue';
import RichText from '@/components/rich-text/RichText.vue';
import type { PropType } from 'vue';
import type { BlogSignUpCard } from '@/types/storyblok';
import { VonageBlogArticleSignUpURL } from '@/constants';
import { useBrandingStore } from '@/store/branding';
import { computed } from 'vue';

defineProps({
  card: { type: Object as PropType<BlogSignUpCard>, required: true },
});
const brandingStore = useBrandingStore();

const isVonageBrand = computed(() => brandingStore.data?.vendorId === 'vonage');
</script>

<style lang="scss" scoped>
.signup-card {
  background: var(--dp-blog-article-sidebar-signup);
}
</style>
