const CharsNotAllowed = /[~`!@#$%^&*()_+=[\]{}|;:"'“”‘’<>,.?\\/]/g;

/**
 * Modified version of original slugify from [markdown-it-anchor]
 * (https://github.com/valeriangalliat/markdown-it-anchor/blob/master/index.js#L3)
 */
export default function slugify(s: string) {
  return (
    String(s)
      .trim()
      .toLowerCase()
      // replace spaces with -
      .replace(/\s+/g, '-')
      // need to remove percent encoding as they break scroll
      .replace(CharsNotAllowed, '')
  );
}
