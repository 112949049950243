<template>
  <div class="relative termynal-container">
    <div class="toolbar absolute top-1 right-1 z-10">
      <CopyToClipboardButton :code="copyableText" />
    </div>
    <div ref="element" class="termynal pt-14 px-6 pb-6 rounded-lg text-[#b8b8b8] block text-base w-full" data-termynal>
      <slot />
    </div>
  </div>
</template>

<script setup lang="ts">
import { onMounted, ref } from 'vue';
import CopyToClipboardButton from '@/components/code/CopyToClipboardButton.vue';
import Termynal from './lib';

const props = defineProps({
  startDelay: { type: Number, default: 100, required: false },
  typeDelay: { type: Number, default: 10, required: false },
  lineDelay: { type: Number, default: 100, required: false },
  lazy: { type: Boolean, default: true, required: false },
  typingEffectEnabled: { type: Boolean, default: false, required: false },
});

const element = ref(null as HTMLElement | null);
const termynal = ref(null as Termynal | null);
const copyableText = ref('' as string);

const dataPrefix = 'data-ty';

const copyAttributes = (fromElement: Element, toElement: Element) => {
  const attrList = [
    'startDelay',
    'typeDelay',
    'lineDelay',
    'progressLength',
    'progressChar',
    'progressPercent',
    'cursor',
  ];
  for (const key of attrList) {
    const attrName = `${dataPrefix}-${key}`;
    const attrVal = fromElement.getAttribute(attrName);
    if (attrVal) {
      toElement.setAttribute(attrName, attrVal);
    }
  }
};

const unwrap = (node: Element) => {
  node.replaceWith(...node.children);
};

const setupTermynal = () => {
  if (!element.value) return;
  const termynalElement = element.value.querySelector('[data-termynal]');
  if (termynalElement) {
    copyAttributes(termynalElement, element.value);
    unwrap(termynalElement);
  }
  termynal.value = new Termynal(element.value, {
    noInit: true,
    startDelay: props.startDelay,
    typeDelay: props.typeDelay,
    lineDelay: props.lineDelay,
  });
};

const createCopyableText = () => {
  if (!element.value) return;
  let text = '';
  const nodes = element.value.querySelectorAll(`[${dataPrefix}]`);
  for (const node of nodes) {
    const type = node.getAttribute(dataPrefix);
    if (type === 'progress') {
      const progressChar = node.getAttribute(`${dataPrefix}-progressChar`) || '█';
      const progressLength = Number(node.getAttribute(`${dataPrefix}-progressLength`)) || 40;
      const percentage = Number(node.getAttribute(`${dataPrefix}-progressPercent`)) || 100;
      const progressBar = progressChar.repeat(progressLength);
      text += `${progressBar} ${percentage}%\n`;
    } else {
      const prompt = node.getAttribute(`${dataPrefix}-prompt`);
      if (prompt) text += `${prompt} `;
      text += `${node.innerHTML}\n`;
    }
  }
  copyableText.value = text.trim();
};

const start = async () => {
  if (!element.value || !termynal.value) return;
  termynal.value.init();
};

const lazyStart = () => {
  if (!element.value) return;
  const observer = new IntersectionObserver(entries => {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        start();
        observer.disconnect();
      }
    });
  });
  observer.observe(element.value);
};

onMounted(() => {
  if (props.typingEffectEnabled) {
    setupTermynal();
    if (props.lazy) lazyStart();
    else start();
  }
  createCopyableText();
});
</script>

<style lang="scss">
@import './lib/termynal.css';

.termynal {
  background: var(--vvd-color-neutral-900);
  font: var(--vvd-typography-base-extended-code);

  &::before {
    width: 12px;
    height: 12px;
    box-shadow:
      20px 0 0 #f4c025,
      40px 0 0 #3ec930;
  }

  pre {
    background-color: inherit;
    margin: 0;
  }
}
</style>
