import useAsyncStoryblokWithI18n from '@/utils/storyblok/useAsycStoryblokWithI18n';
import type { BlogArticleSignUpWidgetConfigStory } from '@/types/storyblok';
import { useBrandingStore } from '@/store/branding';

export const getBlogArticleSignUpWidgetConfigStory = async (): Promise<BlogArticleSignUpWidgetConfigStory | null> => {
  const brandingStore = useBrandingStore();
  try {
    return await useAsyncStoryblokWithI18n(`branding/${brandingStore.data?.vendorId}/config/blog/signup-widget`);
  } catch (e) {
    return null;
  }
  return null;
};
