<template>
  <div v-if="story && isAnonymousUser">
    <RichText :text="story.content.headerSection" type="article" />
    <RichText v-if="blok.number" :text="story.content.numbersSection" type="article" />
    <BlogArticleSignUpCard :card="story.content.signUpCard[0]" />
  </div>
</template>

<script setup lang="ts">
import { type PropType, computed } from 'vue';
import type { BlogArticleSignUpWidgetConfigStory, RtSignUpSection } from '@/types/storyblok';
import RichText from '@/components/rich-text/RichText.vue';
import BlogArticleSignUpCard from '@/components/blog/BlogArticleSignUpCard.vue';
import { useUserStore } from '@/store/user';
import { isUserLoggedIn } from '@/utils/user';
import { getBlogArticleSignUpWidgetConfigStory } from '@/utils/blog/getBlogArticleSignUpWidgetConfigStory';

defineProps({
  blok: { type: Object as PropType<RtSignUpSection>, required: true },
});

const user = useUserStore();

const isAnonymousUser = computed(() => !isUserLoggedIn(user.info));

const story: BlogArticleSignUpWidgetConfigStory = await getBlogArticleSignUpWidgetConfigStory();
</script>

<style lang="scss" scoped></style>
