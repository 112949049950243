<template>
  <component :is="heading" :id="id">
    <NuxtLink type="button" :href="`#${id}`" class="anchor header-icon pt-[4px] flex flex-col justify-center">
      <v-icon name="link-line" :size="-6" />
    </NuxtLink>
    <slot />
  </component>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { getDefaultSlotText } from '@/utils/slots';
import slugify from '@/utils/slugify';
import { VIcon } from '@vonage/vivid-vue';

const props = defineProps({
  level: { type: String, required: true },
});

const heading = computed(() => `h${props.level}`);

const id = computed(() => slugify(getDefaultSlotText()));
</script>
