import { useSlots, type VNode } from 'vue';

function getSlotChildrenText(children: VNode[]): string {
  return children
    .map(node => {
      if (!node.children || typeof node.children === 'string') return node.children || '';
      if (Array.isArray(node.children)) return getSlotChildrenText(node.children as VNode[]);
      // @ts-ignore
      if (node.children.default) return getSlotChildrenText(node.children.default());
      return '';
    })
    .join('');
}

export function getDefaultSlotText() {
  const slots = useSlots();

  return (slots.default && getSlotChildrenText(slots.default())) || '';
}
