import { useBrandingStore } from '@/store/branding';

export function replaceVariablesInLinkWrapper(url: string): string {
  const brandingStore = useBrandingStore();
  const customVariables = {
    CUSTOMER_DASHBOARD_URL: brandingStore.data?.customerDashboardUrl as string,
    CUSTOMER_SUPPORT_URL: brandingStore.data?.customerSupportUrl as string,
    CUSTOMER_SUPPORT_EMAIL: brandingStore.data?.customerSupportEmail as string,
  };
  let replacedString = url;
  Object.entries(customVariables).forEach(([key, value]) => {
    const index = replacedString.indexOf(`\${${key}}`);
    if (index !== -1) {
      const suffix = replacedString.substring(index + key.length + 3);
      replacedString = value + suffix;
    }
  });
  return replacedString;
}
